.timeblockDiv {
    display: flex;
    flex-direction: row;
    align-items: center;

    .p {
        display: flex;
        align-items: center;
        margin-bottom: 0px;
    }

    .pDelete {
        display: flex;
        align-items: center;
        margin-bottom: 0px;
        color: red;
    }

    .optionsMenu {
        margin: 5px;
        margin-left: 15px;
    }
}

.editTimeblocks {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;

    .timepicker {
        width: 50%;
        margin-right: 5px;
    }

    .buttonOffset {
        margin-right: 5px;
    }
}

.optionsItems {
    display: flex;
    flex-direction: column;
}