.mac-book-air-1 {
  color: #223747;
  width: 100%;
  height: 100%;
  background-color: #f6f6f6;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-family: Inter, Arial;
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 29px;
  display: flex;
}

.frame-7 {
  color: #fff;
  width: 100%;
  background-color: #f5f5f5e6;
  border: 0 solid #e66739;
  flex-direction: row;
  flex-basis: 2%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 29px;
  padding-left: 46px;
  padding-right: 46px;
  font-size: 1rem;
  font-weight: 700;
  display: flex;
}

.subtract {
  width: 41px;
  height: 36px;
  object-fit: cover;
}

.frame-1 {
  height: 45%;
  background-color: #e66739;
  border-radius: 24px;
  flex-direction: row;
  flex-basis: 6%;
  justify-content: center;
  align-items: center;
  margin-top: 1px;
  padding-top: 1px;
  display: flex;
}

.login {
  margin-top: 1px;
}

// Landing header
.protect-whats {
  font-weight: 100;
  text-align: center;
  margin-top: 50px;
  font-size: 2rem;
  margin-bottom: 10px;
}

.rectangle-13 {
  align-self: center;
  border: 2px solid #e66739;
  width: 9rem;
  margin-bottom: 10px;
  object-fit: cover;
}

.important {
  text-transform: uppercase;
  text-align: center;
  font-size: 4rem;
  margin-top: 15px;
  margin-bottom: 20px;
}

// Video Container
.video-container {
  display: flex;
  align-self: center;
  width: 67%;
  max-width: 1280px;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 28px;
  font-size: 100%;

  // Left Box
  .frame-5 {
    z-index: 1;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.9019607843);
    border-left: 4px solid #e66739;
    flex-direction: column;
    width: 177px;
    margin-top: 7.5%;
    padding: 16px;
    display: flex;

    .num-3000 {
      text-transform: uppercase;
      margin-top: 4px;
      font-size: 2.5rem;
      line-height: 48px;
    }

    .fatalities-per-year {
      margin-bottom: 5px;
      font-size: 1rem;
      font-weight: 400;
      line-height: 20px;
    }
  }

  // Right Box
  .frame-6 {
    z-index: 1;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.9019607843);
    border-right: 4px solid #e66739;
    flex-direction: column;
    width: 10.5rem;
    justify-content: space-evenly;
    align-self: flex-start;
    align-items: flex-start;
    margin-top: 20%;
    padding: 16px;
    display: flex;

    .num-1-in-5 {
      font-size: 2.5rem;
      line-height: 100%;
    }

    .were-pedestrians-or {
      margin-bottom: 2px;
      font-size: 1rem;
      font-weight: 400;
      line-height: 20px;
      padding-top: 10px;
    }
  }
}

.video-container-mobile {
  width: 80%;

  // Left Box
  .frame-5 {
    background-color: rgba(255, 255, 255, 0.9019607843);
    border-left: 4px solid #e66739;
    border-right: 4px solid #e66739;
    padding: 16px;

    .num-3000 {
      font-weight: bolder;
      font-size: 1.25rem;
    }

    .fatalities-per-year {
      font-weight: 400;
      font-size: 1.25rem;
    }
  }

  // Right Box
  .frame-6 {
    background-color: rgba(255, 255, 255, 0.9019607843);
    border-left: 4px solid #e66739;
    border-right: 4px solid #e66739;
    padding: 16px;

    .num-1-in-5 {
      font-weight: bolder;
      font-size: 1.25rem;
    }

    .were-pedestrians-or {
      font-weight: 400;
      font-size: 1.25rem;
    }
  }
}

.group-3 {
  color: #f4ce42;
  height: 100%;
  flex-direction: row;
  flex-basis: 90%;
  justify-content: space-between;
  align-items: flex-end;
  margin-right: -751px;
  font-size: 2rem;
  font-weight: 700;
  line-height: 39px;
  display: flex;
}

.rectangle-3 {
  width: 507px;
  height: 325px;
  object-fit: cover;
  margin-left: 360px;
  margin-right: -748px;
}

.rectangle-2 {
  width: 528px;
  height: 326px;
  object-fit: cover;
  margin-left: -129px;
  margin-right: -139px;
}

.rectangle-1 {
  width: 767px;
  height: 326px;
  object-fit: cover;
  margin-left: -174px;
  margin-right: -904px;
}

.rectangle-18 {
  height: 100%;
  background-image: url("../assets/rectangle.png");
  background-position: center;
  background-size: cover;
  flex-direction: row;
  flex-basis: 100%;
  justify-content: flex-start;
  align-items: stretch;
  margin-left: 37px;
  padding-top: 21px;
  padding-left: 80px;
  display: flex;
}

.rectangle-22 {
  height: 25%;
  background-color: #0009;
  flex-direction: row;
  flex-basis: 17%;
  justify-content: center;
  align-items: center;
  margin-bottom: 11px;
  margin-left: 100px;
  padding-top: 3px;
  padding-right: 1px;
  display: flex;
}

.alert {
  margin-top: 1px;
}

.group {
  width: 222px;
  height: 86px;
  object-fit: cover;
  margin-top: 2px;
}

// Logo
.roadz_logo {
  display: flex;
  margin: 30px;
  flex-direction: column;


  .roadside-active-defe {
    text-transform: uppercase;
    color: #e66739;
    font-size: .813rem;
    text-align: center;
    line-height: 16px;
  }
}

// What is active defense and bullet points
.defense {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 100px;

  .header {
    text-transform: uppercase;
    text-align: center;
    font-size: 4rem;
    margin-bottom: 33px;
    line-height: 100%;
  }

  .flex-container {
    width: 50%;
    flex-direction: row;
    align-items: center;
    align-self: center;
    margin: 20px;
    display: flex;

    .num {
      color: #e66739;
      font-size: 4rem;
      text-transform: uppercase;
      margin-right: 5%;
      line-height: 35px;
    }

    .info {
      text-transform: uppercase;
      margin-right: -94px;
      line-height: 25px;
    }
  }
}

.defense-mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 50px;

  .header {
    text-transform: uppercase;
    text-align: center;
    font-size: xxx-large;
    margin-bottom: 33px;
    line-height: 100%;
  }

  // Bullet point 1
  .flex-container {
    width: 54%;
    flex-direction: row;
    align-items: center;
    align-self: center;
    margin: 15px;
    display: flex;

    .num {
      text-transform: uppercase;
      color: #e66739;
      font-size: 3rem;
      margin-right: 5%;
    }

    .info {
      text-transform: uppercase;
      width: 95%;
      margin-right: -104px;
      font-size: large;
      line-height: 25px;
    }
  }
}

.group-4 {
  width: 90%;
  margin-bottom: 50px;
  align-self: center;

  .image {
    margin-left: 5%;
    width: 90%;
  }
}

.how-does-it-work {
  text-transform: uppercase;
  text-align: center;
  margin-top: 2px;
  font-size: xxx-large;
  margin-bottom: 34px;
  line-height: 100%;
}

.how-it-works {
  align-items: center;
  display: flex;
  margin-top: 2px;
  margin-bottom: 21px;
  flex-direction: column;

  .flex-container {
    width: 65%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: 33px;
    display: flex;
  }

  .frame-instance {
    height: 100%;
    flex-basis: 48%;
  }
}

.how-it-works-mobile {
  align-items: center;
  display: flex;
  margin-top: 2px;
  margin-bottom: 21px;
  flex-direction: column;

  .flex-container {
    width: 65%;
    justify-content: space-between;
    align-items: center;
  }

  .frame-instance {
    height: 100%;
    flex-basis: 48%;
  }
}

.frame-19 {
  color: #000;
  width: 100%;
  background-color: #fff;
  flex-direction: column;
  flex-basis: 19%;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 2px;
  margin-bottom: 5px;
  padding: 21px 27px 26px 80px;
  font-size: 1rem;
  font-weight: 500;
  display: flex;
}

.contact-us {
  text-transform: uppercase;
  color: #223747;
  margin-top: 4px;
  margin-bottom: 3px;
  font-size: 3rem;
  font-weight: 800;
  line-height: 58px;
}

.name {
  align-self: flex-start;
  margin-top: 1px;
  margin-left: 141px;
}

.rectangle-19 {
  width: 70%;
  background-color: #fff;
  border: 1px solid #717a84;
  border-radius: 4px;
  flex-direction: row;
  flex-basis: 10%;
  justify-content: flex-start;
  align-items: stretch;
  display: flex;
}

.message {
  align-self: flex-start;
  margin-left: 141px;
}

.rectangle-19-1 {
  width: 70%;
  background-color: #fff;
  border: 1px solid #717a84;
  border-radius: 4px;
  flex-direction: row;
  flex-basis: 32%;
  justify-content: flex-start;
  align-items: stretch;
  display: flex;
}

.frame-1-1 {
  color: #fff;
  width: 10%;
  background-color: #e66739;
  border: none;
  border-radius: 24px;
  flex-direction: row;
  flex-basis: 6%;
  justify-content: center;
  align-items: center;
  margin-bottom: 4px;
  font-family: Inter, Arial;
  font-size: 1rem;
  font-weight: 700;
  line-height: 19px;
  display: flex;
}

.privacy {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 6px;
  font-size: 1rem;
  font-weight: 500;
}