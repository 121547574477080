.notificationsDiv {
    width: 50%;

    .checkboxesDiv {
        margin-bottom: 10px;
    }

    .reminderTitle {
        display: flex;
        flex-direction: row;
    }

    .option {
        display: flex;
        flex-direction: row;
        align-items: flex-start;

        .label{
            width: 65px;
            margin-right: 15px;
        }
    }
}

.tooltipDiv {
    display: flex;
    flex-direction: column;

    .weeklyInput {
        display: flex;
        flex-direction: row;
        width: 260px
    }

    .monthlyDayInput {
        width: 65px;
    }

    .monthlyTimeInput {
        margin-left: 5px;
    }

    .divider {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}