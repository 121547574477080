.card-frame-12 {
    color: #223747;
    flex-direction: row;
    // justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    padding-left: 3px;
    padding-right: 8px;
    font-family: Inter, Arial;
    font-size: 4rem;
    font-weight: 800;
    // line-height: 77px;
    display: flex;
  }
  
  .num-04 {
    text-transform: uppercase;
    color: #e66739;
    margin-top: 1px;
  }
  
  .flex-container-7 {
    height: 95%;
    flex-direction: column;
    justify-content: space-between;
    align-self: flex-end;
    align-items: stretch;
    font-size: 1rem;
    line-height: 29px;
    display: flex;
    margin-right: 10px;
    margin-left: 10px;
  }
  
  .machine-learning-ai {
    text-transform: uppercase;
    font-size: 1.5rem;
    margin-left: 10px;
  }
  
  .deep-neural-networks {
    font-weight: 500;
    line-height: 19px;
    margin-left: 10px;
  }
  