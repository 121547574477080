.menuDiv {
    width: 50%;

    .timeblockTitle {
        display: flex;
        flex-direction: row;

        .infoCircleOffset {
            margin-bottom: 10px;
            display: flex;
            align-items: center;
        }
    }

    .menuCard {
        margin-bottom: 5px;
    }
}