.sider {
    padding: 5px 10px;
    height: fit-content;

    h2 {
        cursor: default;
    }

    .list {
        list-style-type: none;

        .item {
            cursor: default;
            border-bottom: 1px solid rgba(125, 125, 125, .5);
            padding: 5px 0px;
            padding-left: 10px;
        }
    }

    .list .item:last-child {
        border-bottom: none;
    }
}

.workingZone {
    margin: 10px 50px;
    margin-bottom: 0px;

    .topRow {
        display: flex;
        flex-direction: row;

        .topRowSegment {
            margin: 0px 5px;
            background-color: rgba(125, 125, 125, .25);
        }

        .topRowButton {
            margin: 0px 5px
        }

        .helpText {
            width: fit-content;
            margin: auto 10px;
        }
    }

    .configs {
        display: flex;
        flex-direction: row;
        margin-top: 20px;
        justify-content: space-evenly;
    }
}