.settings {
    display: flex;
    flex-direction: column;
    align-items: center;

    .card {
        max-width: 1000px;
        width: 100%;

        .information {
            margin-top: 10px;

            .title {
                display: flex;
                justify-content: center;
            }

            .input {
                max-width: 250px;
                margin-left: 5px;
            }

            .inputError {
                border-color: red;
            }

            .row {
                display: flex;
                flex-direction: row;
            }

            .centerItems {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-bottom: 10px;

                .buttonOffset {
                    margin-right: 10px;
                    margin-left: 10px;
                }

                .pOffset {
                    margin-left: 10px;
                }
            }
        }

        .saveReset {
            margin-top: 10px;
        }
    }
}