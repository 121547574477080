.dataTable {
    border-collapse: collapse;
    border: 1px solid black;
    margin-bottom: 10px;
}

tr:nth-child(even).tableRow {
    background-color: #f2f2f2;
}

tr:hover.tableRow {
    background-color: orange;
}

$randomnumber: random(255);

td:hover.data {
    cursor: pointer;
    background-color: rgb(233, 130, 3)
}

th.tableData, td.tableData {
    padding: 5px;
    border: 1px solid black;
}