.group::-webkit-scrollbar {
    width: 10px;
}

.group::-webkit-scrollbar-thumb {
    background-color: orange;
}

.group::-webkit-scrollbar-thumb:hover {
    background-color: darkorange;
}