.cardDiv {
    display: flex;
    flex-direction: row;

    .cardLeft {
        width: 50%;
        border: none;
        margin-right: 5px;
    }

    .cardRight {
        width: 50%;
        border: none;
    }

    .divider {
        height: 250px;
    }
}

@media (max-width: 600px) {
    .cardDiv {
        flex-direction: column;
    
        .cardLeft,
        .cardRight {
            width: 100%
        }
    }

    .divider {
        height: 2px;
    }
}