.groups {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 500px;

    .buttons {
        display: flex;
        flex-direction: column;
        width: 30%;
        // justify-content: space-between;
        height: fit-content;

        .title {
            margin: 0px auto;
            margin-bottom: 10px;
            text-decoration: underline;
        }

        .button {
            margin-bottom: 10px;
            align-self: center;
            width: 100%;
        }

        .saveButtons {
            display: flex;
            justify-content: space-evenly;
        }
    }

    .divider {
        height: 250px;
        // margin: 20px 0;
    }

    .list {
        display: flex;
        flex-direction: row;
        border: 1px solid lightgray;
        border-radius: 5px;
        width: 300px;
        height: 200px;
        padding: 5px 10px;
        overflow-y: auto;
        justify-content: space-between;

        .listConfig {
            width: fit-content;
            list-style-type: none;

            .configItem {
                border-bottom: 1px solid rgba(125, 125, 125, 0.25);
                padding: 5px 10px;
                list-style-type: none;
            }

            .configItem:hover {
                cursor: default;
            }

            .configItemHover:hover {
                background-color: rgba(0, 125, 255, .25);
                cursor: pointer;
            }

            .configItemDelete:hover {
                background-color: rgba(255, 0, 0, .25);
                cursor: pointer;
            }

            .configItem:last-child {
                border-bottom: none;
            }
        }
    }
}