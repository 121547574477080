.centerItems {
    display: flex;
    flex-direction: row;
    align-items: center;

    .pMargin {
        margin-bottom: 0px;
        margin-right: 10px;
    }

    .codeInput {
        margin-right: 10px;
    }
}