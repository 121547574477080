@import url("~leaflet/dist/leaflet.css");
// @import "~antd/dist/antd.css";


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif !important;
}

body {
  //overflow: hidden;
}

body #root {

  // for priority
  .anticon.bolded {
    color: #222;
    font-weight: bolder;
  }

  .anticon.disabled {
    opacity: 0.5;
    color: #AAA;
    cursor: not-allowed;
  }
}

body .ant-modal-root .video-wrapper {
  .ant-modal-confirm-body .ant-modal-confirm-content {
    margin: 0;
    background-color: #F6F6F6;
  }
}

.ant-menu-submenu {
  z-index: 1;
}

.ant-menu-submenu-popup .ant-menu-vertical.ant-menu-sub {
  max-height: 80vh;
}

.layout {
  min-height: 100vh;
}

@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

$xs: "(max-width: 576px)";
$sm: "(min-width: 576px) and (max-width: 768px)";
$md: "(min-width: 768px) and (max-width: 992px)";
$lg: "(min-width: 992px) and (max-width: 1200px)";
$xl: "(min-width: 1200px) and (max-width: 1600px)";
$xxl: "(min-width: 1600px)";

// media queries
@media #{$xs} {}

@media #{$sm} {}

@media #{$md} {}

@media #{$lg} {}

@media #{$xl} {}


@media #{$xxl} {}

#map {
  width: 100%;
  height: calc(100vh - 48px);
}

// NAV

.site-layout-content {
  min-height: 680px;
  padding: 10px;
  background: #fff;


  .cardBorder {
    border: none;
  }

  .map {
    z-index: 1;
  }
}

.content {
  background: #e0e0e0;
  height: 100%;
}

.layout .logo {
  float: right;
  width: 42px;
  height: 36px;
  margin: 16px 24px 16px 0px;
  background-image: url("../img/subtract.svg");
  background-repeat: no-repeat;
  // background-size: cover;
  cursor: pointer;
}

.ant-row-rtl .layout .logo {
  float: right;
  margin: 16px 0 16px 24px;
}


// LOGIN

.login-form {
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
}

.password-form {
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
}

// ACCOUNT

.card-item {
  display: flex;
  flex-direction: row;

  .ant-card-body {
    flex-grow: 1;
  }

  &.status-pending {
    background: rgba(24, 144, 255, 0.1);

    .ant-card-actions {
      background: rgba(24, 144, 255, 0.1);
    }
  }

  &.status-error {
    background: rgba(255, 77, 79, 0.1);

    .ant-card-actions {
      background: rgba(255, 77, 79, 0.1);
    }
  }

  &.status-rejected {
    background: rgba(217, 217, 217, 0.1);

    .ant-card-actions {
      background: rgba(217, 217, 217, 0.1);
    }
  }

  &.status-completed {
    background: rgba(82, 196, 26, 0.1);

    .ant-card-actions {
      background: rgba(82, 196, 26, 0.1);
    }
  }
}

.fancyBorder {
  border: 1px solid darkgray;
  border-radius: 5px;
}

.vehicleSelector {
  background: rgba(24, 144, 255, 0.1);
  height: 100%;
  overflow-y: auto;
  height: 760px;
}

.landingCardGrid {
  width: 50%;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.landingForm {
  display: flex;
  align-content: center;
  flex-direction: column;
}

.landingCardImage {
  align-self: center;
}


::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #e0e0e0;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}




// INFO PANEL

.vehicleComponent {
  margin-top: 2px;
  margin-bottom: 2px;
  font-size: 16px;
  cursor: pointer;
  padding: 4px;
}

.vehicleComponentSelected {
  color: aliceblue;
  //background-color: #111169;
  margin-top: 2px;
  margin-bottom: 2px;

  font-size: 14px;
  padding: 4px;
}

.VideoPlayer {
  background-color: #000;
}