.h2 {
    margin: 5px;
}

.modal {
    display: flex;
    flex-direction: row;

    .halfModal {
        width: 50%;

        .downloadButton {
            display: flex;
            align-items: center;

            .downloadB {
                margin-right: 5px;
            }
        }
    }

    .rightHalf {
        margin-left: 20px;
    }

    .divider {
        height: 250px;
    }
}