.manageBox {
    width: 60vw;
    height: fit-content;
    border: 1px solid black;
    background-color: white;
    border-radius: 5px;
    padding: 10px 25px;
    margin-bottom: 20px;

    .boxTitle {
        margin: auto 15px;
        padding: 10px 0px;
    }

    .boxDivider {
        margin: 10px 0px;
    }
}

.ant-cascader-menu {
    height: fit-content !important;
    max-height: 180px !important;
    overflow-y: auto !important;
}