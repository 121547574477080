.p {
    margin-bottom: 0px;
    margin-top: 5px;
}

.ul {
    margin: 0px;
}

.li {
    margin-left: 20px;
}

.error {
    color: red;
}