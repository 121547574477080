.date-picker {
    margin-left: 10px;
}

.range-picker {
    margin-left: 10px;
    margin-top: 5px;
}

.popover {
    margin-left: 20px;
    margin-top: 20px;
}

.change-view {
    display: flex;
    flex-direction: row;
    align-items: center;
}