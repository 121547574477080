.emailForm {
    width: 25%;
    margin-left: auto !important;
    margin-right: auto !important;
}

.resetForm {
    width: 30%;
    margin-left: auto !important;
    margin-right: auto !important;

    .p {
        margin-bottom: 0px;
    }

    .li {
        margin-left: 20px;
    }

    .error {
        color: red;
    }
}