.infoPanel {
    border: 1px solid darkgray !important;
    border-radius: 5px !important;
    height: fit-content;
    max-height: 620px;
    background: rgb(24, 144, 255, 0.1) !important;

    b {
        margin-right: 5px;
    }

    .secondB {
        margin-left: 10px;
    }

    .smallerDivider {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .gpsText {
        margin-right: 3px;
    }

    .statusList {
        display: flex;
        flex-direction: row;
        align-items: center;

        .textBox {
            margin-top: 5px;
            height: 100px;
            width: 100%;
            background-color: white;
            border: 1px solid lightgray;
            overflow-y: scroll;
        }

        .textBox li {
            margin-left: 25px;
        }
    }

    .cancelButton {
        position: relative;
        margin-left: 5px;
    }

    .modalDownload {
        width: 100%;
        margin-top: 15px;
    }

    .latestButton {
        width: 100%;
        margin-top: 5px;
    }

    .metricsButton {
        border: 1px solid lightgrey;
        border-radius: 5px;
        padding: 5px 10px;
        background-color: white;
        color: black;
    }

    .metricsButton:hover {
        border: 1px solid #4096ff;
        color: #4096ff;
    }
}