.form {
    display: flex;
    flex-direction: column;
    align-items: center;

    .button {
        color: #ffffff;
        background: #E66739;
        border: none;
        border-radius: 24px;
        margin-top: 15px;
        font-weight: bold;
        width: 90px;
        height: 30px;
    }

    .button:hover {
        border: 2px solid black
    }

    .button-disabled {
        color: #ffffff;
        background: #d69178;
        border: none;
        border-radius: 24px;
        margin-top: 15px;
        font-weight: bold;
        width: 90px;
        height: 30px;
    }

    .input {
        font-family: Inter, Arial;
        padding-left: 12px;
        width: 70%;
        height: 45px;
        margin-bottom: 15px;
        border-radius: 5px;
        border: 1px solid #000000;
    }

    .input-error {
        font-family: Inter, Arial;
        padding-left: 12px;
        width: 70%;
        height: 45px;
        margin-bottom: 15px;
        border-radius: 5px;
        border: 1px solid red;
    }

    .text {
        font-family: Inter, Arial;
        padding: 12px;
        width: 70%;
        margin-bottom: 15px;
        border-radius: 5px;
        border: 1px solid black;
    }

    .text-error {
        font-family: Inter, Arial;
        padding: 12px;
        width: 70%;
        margin-bottom: 15px;
        border-radius: 5px;
        border: 1px solid red;
    }

    .input:hover,
    .text:hover {
        border: 2px solid black
    }

    .recaptcha {
        transform: scale(0.77);
    }
}

.form-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;

    .button {
        color: #ffffff;
        background: #E66739;
        border: none;
        border-radius: 24px;
        margin-top: 15px;
        font-weight: bold;
        width: 90px;
        height: 30px;
    }

    .button:hover {
        border: 1px solid black
    }

    .button-disabled {
        color: #ffffff;
        background: #d69178;
        border: none;
        border-radius: 24px;
        margin-top: 15px;
        font-weight: bold;
        width: 90px;
        height: 30px;
    }

    .input {
        padding-left: 3px;
        margin-bottom: 15px;
        border-radius: 5px;
        width: 65%;
        min-width: 200px;
        border: 1px solid #000000;
    }

    .input-error {
        padding-left: 3px;
        margin-bottom: 15px;
        border-radius: 5px;
        width: 65%;
        min-width: 200px;
        border: 1px solid red;
    }

    .text {
        padding: 3px;
        width: 65%;
        min-width: 200px;
        margin-bottom: 15px;
        border-radius: 5px;
        border: 1px solid #000000;
    }

    .text-error {
        padding: 3px;
        width: 65%;
        min-width: 200px;
        margin-bottom: 15px;
        border-radius: 5px;
        border: 1px solid red;
    }

    .recaptcha {
        transform: scale(0.77);
        margin-left: -30px;
    }
}