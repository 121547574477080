.dayInputBox {
    width: 50% !important;
    margin-right: 5px !important;
}

.dayTimeInputBox {
    width: 50% !important;
    margin-right: 5px !important;
}


.dayDiv {
    display: flex;
    justify-content: center;

    .dayButton {
        width: 150px;
        margin: 5px;
    }

    .singleDayButton {
        width: 150px;
    }
}

.hr {
    border-width: 1px;
    border-style: none;
    border-top-style: solid;
    border-top-color: #f2f2f2;
}

.okButton {
    display: flex;
    justify-content: flex-end;

    .buttonOffset {
        margin-top: 10px;
    }
}
